<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Tạo mới</h6>
        </template>
        <b-row>
          <b-col sm="6">
            <b-form-group label="Mô tả (*)">
              <b-form-input
                v-model="input.name"
                placeholder="Tên route app"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="6">
            <b-form-group label="Loại (*)">
              <b-form-select
                  v-model="input.type"
                  :options="this.options.type"
              >
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="6">
            <b-form-group label="Url">
              <b-form-input
                  v-model="input.value"
                  placeholder="Url"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <template v-for="index in 5">
        <b-row  v-if="input.type != 'URL'" v-bind:key="index">
          <b-col sm="3">
            <b-form-group >
              <b-form-input
                v-model="params[index]"
                placeholder="Param"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col >
            <b-form-group>
              <b-form-input
                  v-model="values[index]"
                  placeholder="Value"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        </template>
<!--        <b-row>-->

<!--          <b-col sm="1">-->
<!--            <b-form-group>-->
<!--              <label>-->
<!--                <input type="checkbox" v-model="checkBox">-->
<!--                Kích hoạt-->
<!--              </label>-->
<!--            </b-form-group>-->
<!--          </b-col>-->
<!--        </b-row>-->

        <b-row>
          <b-col sm="6">
            <b-form-group label="Trạng thái (*)">
              <b-form-select
                  v-model="input.status"
                  :options="options.status"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="text-center">
            <b-button class="mr-2" v-if="this.id == '' || this.id == null" variant="outline-primary" @click="store()"
            >Thêm mới
            </b-button>
            <b-button class="mr-2" v-if="this.id != '' && this.id != null" variant="outline-primary" @click="update()"
            >Lưu
            </b-button>
            <b-button class="mr-2" variant="outline-danger" @click="cancel()"
            >Hủy
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";
import "vue2-datepicker/index.css";
import Multiselect from "vue-multiselect";
import CmsRepository from "@/core/repositories/cmsRepository";

Vue.component("multiselect", Multiselect);
Vue.use(Notifications);
export default {
  mixins: [Common],
  data() {
    return {
      input: {
        type: 'WEBVIEW',
        status : 1,
        name: null,
      },
      screenId: null,
      checkBox: true,
      params: [],
      values: [],
      id: this.$route.query.id ?? null,
      options: {
        status: [
          { value: 1, text: "Hiển thị" },
          { value: 2, text: "Bảo trì" },
          { value: 0, text: "Ẩn" },
          { value: -1, text: "Đã xóa" },
        ],
        type: [
          { value: 'WEBVIEW', text: "WEBVIEW" },
          { value: 'URL', text: "URL" },
          { value: 'SCREEN', text: "SCREEN" },
        ],
      }
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Config route app", route: '/tools/route-app' },
    ]);
    if (this.$route.query.id != '' && this.$route.query.id != null) {
      this.getDetail(this.$route.query.id)
    }
  },
  methods: {
    cancel() {
      return this.$router.push({ name: "routeApp" });
    },
    validateStoreForm() {
      if (this.input.name === "" || this.input.name === null) {
        this.notifyAlert("warn", "Mô tả không được để trống");
        return false;
      }
      return true;
    },
    store() {
      if (!this.validateStoreForm()) {
        return;
      }

      let inputParam = this.input;
      this.params = this.params.filter(item => item != null);
      this.values = this.values.filter(item => item != null);
      inputParam.params = this.params;
      inputParam.values = this.values;
      this.$bus.$emit("show-loading", true);
      CmsRepository.storeRouteApp(inputParam)
          .then((response) => {
            if (response) {
              this.notifyAlert("success", response.data.message);
              this.$bus.$emit("show-loading", false);
              return this.$router.push({ name: "routeApp" });
            } else {
              this.notifyAlert("warn", response.data.message);
              this.$bus.$emit("show-loading", false);
              return false;
            }
          })
          .catch(() => {
            alert("Có lỗi xảy ra");
          });
    },
    update() {
      if (!this.validateStoreForm()) {
        return;
      }
      let inputParam = this.input;
      this.params = this.params.filter(item => item !== null);
      this.values = this.values.filter(item => item !== null);
      inputParam.params = this.params;
      inputParam.values = this.values;
      this.$bus.$emit("show-loading", true);
      inputParam.id = this.id
      CmsRepository.updateRouteApp(this.id, inputParam)
          .then((response) => {
            if (response.data.error_code == 1) {
              // this.notifyAlert("success", response.data.message);
              this.$bus.$emit("show-loading", false);
              return this.$router.push({ name: "routeApp" });
            } else {
              this.notifyAlert("success", response.data.message);
              this.$bus.$emit("show-loading", false);
              return this.$router.push({ name: "routeApp" });
            }
          })
          .catch(() => {
            alert("Có lỗi xảy ra");
          });
    },
    async getDetail(id){
      this.$bus.$emit("show-loading", true);
      await CmsRepository.detailRouteApp(id)
          .then((response) => {
            if (response.data.error_code !== 0) {
              this.notifyAlert("warn", response.data.message);
              this.$bus.$emit("show-loading", false);
              return false;
            }

            let result = response.data.data.data;

            this.input.name = result.name;
            this.input.type = result.type;
            this.input.value = result.value;
            this.input.status = result.status;

            if (result.params) {
              let index = 1;
              let content = JSON.parse(result.params)

              for (let item in content) {
                this.params[index] = item;
                this.values[index] = content[item];
                index++;
              }
            }

            this.$bus.$emit("show-loading", false);
          })
          .catch((error) => {
            alert(error);
            this.$bus.$emit("show-loading", false);
          });
    }
  },

  async created() {
  },
};
</script>

